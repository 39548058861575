define("discourse/plugins/discourse-global-filter/discourse/components/category-matrix-checkbox", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryMatrixCheckbox extends _component.default {
    get isChecked() {
      return this.args.filterTag.categories.some(f1 => f1.id === this.args.category.id);
    }
    onChange(e1) {
      const splitFilterCategoryIds1 = this.args.filterTag.category_ids;
      let categoryIds1 = splitFilterCategoryIds1 === "" ? [] : this.args.filterTag.category_ids.split("|").map(str1 => Number(str1));
      if (e1.target.checked) {
        categoryIds1.push(this.args.category.id);
      } else {
        categoryIds1 = categoryIds1.filter(c1 => c1 !== this.args.category.id);
      }
      this.args.setCategoryIdsForTag(this.args.filterTag, categoryIds1);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <input
          {{on "change" this.onChange}}
          type="checkbox"
          checked={{this.isChecked}}
        />
      
    */
    {
      "id": "cE+EcwGy",
      "block": "[[[1,\"\\n    \"],[11,\"input\"],[16,\"checked\",[30,0,[\"isChecked\"]]],[24,4,\"checkbox\"],[4,[32,0],[\"change\",[30,0,[\"onChange\"]]],null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/category-matrix-checkbox.js",
      "scope": () => [_modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryMatrixCheckbox;
});