define("discourse/plugins/discourse-global-filter/discourse/initializers/full-page-search-with-filter", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-global-filter";
  var _default = _exports.default = {
    name: "full-page-search-with-filter",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_global_filter_enabled) {
        (0, _pluginApi.withPluginApi)("1.3.0", api => {
          api.modifyClass("controller:full-page-search", {
            pluginId: PLUGIN_ID,
            init() {
              this._super(...arguments);
              const filterPref = this.currentUser?.custom_fields?.global_filter_preference || siteSettings.global_filters.split("|")[0];
              this.set("q", `tags:${filterPref}`);
            }
          });
        });
      }
    }
  };
});