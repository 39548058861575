define("discourse/plugins/discourse-global-filter/discourse/initializers/filtered-categories-for-global-filter", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-global-filter";
  var _default = _exports.default = {
    name: "filtered-categories-for-global-filter",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_global_filter_enabled) {
        (0, _pluginApi.withPluginApi)("1.3.0", api => {
          api.modifyClass("controller:discovery/categories", {
            pluginId: PLUGIN_ID,
            queryParams: ["tag"]
          });
          api.modifyClass("route:discovery/categories", {
            pluginId: PLUGIN_ID,
            queryParams: {
              tag: {
                refreshModel: true
              }
            }
          });
        });
      }
    }
  };
});