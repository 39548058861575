define("discourse/plugins/discourse-global-filter/discourse/components/global-filter/header", ["exports", "@ember/component", "select-kit/components/multi-select/multi-select-header", "@ember/template-factory"], function (_exports, _component, _multiSelectHeader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="select-kit-header-wrapper">
    {{#each this.selectedContent as |item|}}
      <GlobalFilter::SelectedChoiceName @item={{item}} />
    {{else}}
      <span class="no-selection">
        {{i18n "global_filter.composer_dropdown.none"}}
      </span>
    {{/each}}
    {{d-icon this.caretIcon class="caret-icon"}}
  </div>
  */
  {
    "id": "WykrFnAC",
    "block": "[[[10,0],[14,0,\"select-kit-header-wrapper\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"selectedContent\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@item\"],[[30,1]]],null],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,1],[14,0,\"no-selection\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"global_filter.composer_dropdown.none\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[1,[28,[35,4],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n\"],[13]],[\"item\"],false,[\"each\",\"-track-array\",\"global-filter/selected-choice-name\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-global-filter/discourse/components/global-filter/header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _multiSelectHeader.default.extend({}));
});