define("discourse/plugins/discourse-global-filter/select-kit/addon/components/global-filter-chooser", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/plugin-api", "select-kit/components/multi-select"], function (_exports, _object, _ajax, _pluginApi, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    pluginApiIdentifiers: ["global-filter-chooser"],
    classNames: ["global-filter-chooser"],
    valueProperty: "name",
    selectKitOptions: {
      selectedChoiceComponent: "global-filter/selected-choice",
      headerComponent: "global-filter/header"
    },
    didInsertElement() {
      this._super(...arguments);
      this.setCategoriesForFilter();
    },
    get filtersWithChildren() {
      return this.loadAdditionalFilters(this.site.global_filters);
    },
    selectedContent: (0, _object.computed)("value.[]", "content.[]", "filtersWithChildren.[]", function () {
      if (!this.value) {
        return [];
      }
      return this.filtersWithChildren.filter(filterTag => this.value.includes(filterTag.name));
    }),
    get content() {
      if (!this.value) {
        return [];
      }

      // set remaining dropdown content values
      return this.filtersWithChildren.filter(filterTag => !this.value.includes(filterTag.name));
    },
    select(value) {
      const updatedValues = [...this.value, value];
      this.updateCategoryDropdown(updatedValues);
      this._super(...arguments);
    },
    deselect(value) {
      const updatedValues = this.value.filter(tag => tag !== value.name);
      this.updateCategoryDropdown(updatedValues);
      this._super(...arguments);
    },
    updateCategoryDropdown(tags) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        (0, _ajax.ajax)(`/global_filter/filter_tags/categories_for_filter_tags.json`, {
          data: {
            tags
          }
        }).then(model => {
          api.modifySelectKit("category-chooser").replaceContent(categoryDrop => {
            if (!categoryDrop.selectKit.filter) {
              const categoriesAndSubcategories = model.categories.concat(model.subcategories);
              const filteredSubcategories = categoryDrop.content.filter(c => {
                const categoriesByName = categoriesAndSubcategories.map(item => item["name"]);
                return categoriesByName.includes(c.name || categoryDrop.allCategoriesLabel || categoryDrop.noCategoriesLabel);
              });
              return filteredSubcategories;
            }
          });
        });
      });
    },
    setCategoriesForFilter() {
      // update category dropdown with valid values
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        (0, _ajax.ajax)(`/global_filter/filter_tags/categories_for_current_filter.json`).then(model => {
          api.modifySelectKit("category-chooser").replaceContent(categoryDrop => {
            if (!categoryDrop.selectKit.filter) {
              const categoriesAndSubcategories = model.categories.concat(model.subcategories);
              const filteredSubcategories = categoryDrop.content.filter(c => {
                const categoriesByName = categoriesAndSubcategories.map(item => item["name"]);
                return categoriesByName.includes(c.name || categoryDrop.allCategoriesLabel || categoryDrop.noCategoriesLabel);
              });
              return filteredSubcategories;
            }
          });
        });
      });
    },
    loadAdditionalFilters(globalFilters) {
      let children = [];
      globalFilters.forEach(gf => {
        gf.filter_children && children.push(...Object.values(gf.filter_children));
      });
      const filters = this.siteSettings.replace_global_filter_with_children ? globalFilters.filter(f => !f.filter_children) : globalFilters;
      return [...filters, ...children];
    },
    modifyComponentForRow() {
      return "global-filter-chooser-row";
    }
  });
});