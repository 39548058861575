define("discourse/plugins/discourse-global-filter/discourse/templates/connectors/bread-crumbs-right/filtered-categories-chooser", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GlobalFilter::FilteredCategoriesChooser
    @tagId={{this.tagId}}
    @editingCategory={{this.editingCategory}}
    @editingCategoryTab={{this.editingCategoryTab}}
    @categoryBreadcrumbs={{this.categoryBreadcrumbs}}
  />
  
  {{#if this.additionalTags}}
    <li class="filtered-tags-intersection-drop">
      <TagsIntersectionChooser
        @currentCategory={{this.currentCategory}}
        @mainTag={{null}}
        @additionalTags={{this.additionalTags}}
        @options={{hash allowAny=true}}
      />
    </li>
  {{/if}}
  */
  {
    "id": "Fmafcvyb",
    "block": "[[[8,[39,0],null,[[\"@tagId\",\"@editingCategory\",\"@editingCategoryTab\",\"@categoryBreadcrumbs\"],[[30,0,[\"tagId\"]],[30,0,[\"editingCategory\"]],[30,0,[\"editingCategoryTab\"]],[30,0,[\"categoryBreadcrumbs\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"additionalTags\"]],[[[1,\"  \"],[10,\"li\"],[14,0,\"filtered-tags-intersection-drop\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@currentCategory\",\"@mainTag\",\"@additionalTags\",\"@options\"],[[30,0,[\"currentCategory\"]],null,[30,0,[\"additionalTags\"]],[28,[37,3],null,[[\"allowAny\"],[true]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"global-filter/filtered-categories-chooser\",\"if\",\"tags-intersection-chooser\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-global-filter/discourse/templates/connectors/bread-crumbs-right/filtered-categories-chooser.hbs",
    "isStrictMode": false
  });
});