define("discourse/plugins/discourse-global-filter/discourse/components/global-filter/composer-container", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-global-filter/select-kit/addon/components/global-filter-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _globalFilterChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GlobalFilterComposerContainer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get canDisplay() {
      return this.args.composer.creatingTopic === true && !this.args.composer.creatingPrivateMessage || this.args.composer.editingFirstPost === true && !this.args.composer.privateMessage;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.canDisplay}}
          <div class="global-filter-composer-container">
            <GlobalFilterChooser
              @value={{@composer.tags}}
              @onChange={{action (mut @composer.tags)}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "ob+GlYM4",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"canDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"global-filter-composer-container\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@value\",\"@onChange\"],[[30,1,[\"tags\"]],[28,[31,1],[[30,0],[28,[31,2],[[30,1,[\"tags\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@composer\"],false,[\"if\",\"action\",\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/global-filter/composer-container.js",
      "scope": () => [_globalFilterChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GlobalFilterComposerContainer;
});