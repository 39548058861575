define("discourse/plugins/discourse-global-filter/discourse/components/admin/filter-alternate-name", ["exports", "@ember/component", "@ember/modifier", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _modifier, _i18n, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FilterAlternateName = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div>
      <h4>{{i18n "global_filter.admin.alternate_child_tag_name"}}</h4>
      <Input
        @value={{@filterTag.alternate_name}}
        data-tag-name={{@filterTag.name}}
        {{on "input" @setAlternateNameForFilter}}
        placeholder={{i18n "global_filter.admin.alternate_name_placeholder"}}
      />
  
      <div class="composer-only-wrapper">
        <Input
          @type="checkbox"
          name="composerOnly"
          class="composer-only-input"
          @checked={{@filterTag.alternate_composer_only}}
          data-tag-name={{@filterTag.name}}
          {{on "change" @setAlternateComposerOnlyForFilter}}
        />
        <label class="global-filter-label">
          {{i18n "global_filter.admin.composer_only"}}
        </label>
      </div>
    </div>
  
  */
  {
    "id": "E270KDbR",
    "block": "[[[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,[28,[32,0],[\"global_filter.admin.alternate_child_tag_name\"],null]],[13],[1,\"\\n    \"],[8,[32,1],[[16,\"data-tag-name\",[30,1,[\"name\"]]],[16,\"placeholder\",[28,[32,0],[\"global_filter.admin.alternate_name_placeholder\"],null]],[4,[32,2],[\"input\",[30,2]],null]],[[\"@value\"],[[30,1,[\"alternate_name\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"composer-only-wrapper\"],[12],[1,\"\\n      \"],[8,[32,1],[[24,3,\"composerOnly\"],[24,0,\"composer-only-input\"],[16,\"data-tag-name\",[30,1,[\"name\"]]],[4,[32,2],[\"change\",[30,3]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"alternate_composer_only\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[14,0,\"global-filter-label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"global_filter.admin.composer_only\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@filterTag\",\"@setAlternateNameForFilter\",\"@setAlternateComposerOnlyForFilter\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/admin/filter-alternate-name.js",
    "scope": () => [_i18n.default, _component.Input, _modifier.on],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "filter-alternate-name:FilterAlternateName"));
  var _default = _exports.default = FilterAlternateName;
});