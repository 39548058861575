define("discourse/plugins/discourse-global-filter/discourse/templates/admin-plugins-filter-tags", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <FilterTagsEditor @filterTags={{model.filter_tags}} />
  <h3>{{i18n "global_filter.admin.category_matrix"}}</h3>
  <div class="filter-tag-admin-pane">
    <CategoryMatrix
      @filterTags={{model.filter_tags}}
      @setCategoryIdsForTag={{this.setCategoryIdsForTag}}
    />
  </div>
  */
  {
    "id": "6uRQJcUL",
    "block": "[[[8,[39,0],null,[[\"@filterTags\"],[[30,0,[\"model\",\"filter_tags\"]]]],null],[1,\"\\n\"],[10,\"h3\"],[12],[1,[28,[35,1],[\"global_filter.admin.category_matrix\"],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"filter-tag-admin-pane\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@filterTags\",\"@setCategoryIdsForTag\"],[[30,0,[\"model\",\"filter_tags\"]],[30,0,[\"setCategoryIdsForTag\"]]]],null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-global-filter/discourse/templates/admin-plugins-filter-tags.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-global-filter/discourse/templates/admin-plugins-filter-tags.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"filter-tags-editor\",\"i18n\",\"category-matrix\"]]",
    "moduleName": "discourse/plugins/discourse-global-filter/discourse/templates/admin-plugins-filter-tags.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});