define("discourse/plugins/discourse-global-filter/discourse/services/global-filter", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GlobalFilterCurrentService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "categoryDropContent", [_tracking.tracked], function () {
      return {
        categories: [],
        subcategories: []
      };
    }))();
    #categoryDropContent = (() => (dt7948.i(this, "categoryDropContent"), void 0))();
  }
  _exports.default = GlobalFilterCurrentService;
});