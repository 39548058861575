define("discourse/plugins/discourse-global-filter/discourse/templates/connectors/before-composer-fields/global-filter-items", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GlobalFilter::ComposerContainer @composer={{this.model}} />
  */
  {
    "id": "0JycQZgi",
    "block": "[[[8,[39,0],null,[[\"@composer\"],[[30,0,[\"model\"]]]],null]],[],false,[\"global-filter/composer-container\"]]",
    "moduleName": "discourse/plugins/discourse-global-filter/discourse/templates/connectors/before-composer-fields/global-filter-items.hbs",
    "isStrictMode": false
  });
});