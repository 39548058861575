define("discourse/plugins/discourse-global-filter/discourse/components/global-filter/filtered-tags-composer-chooser", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/components/popup-input-tip", "select-kit/components/mini-tag-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _popupInputTip, _miniTagChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilteredTagsComposerChooser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get hiddenValues() {
      const children1 = this.site.global_filters.flatMap(gf1 => gf1.filter_children ? Object.keys(gf1.filter_children) : []);
      const globalFilters1 = this.siteSettings.global_filters.split("|");
      return [...globalFilters1, ...children1];
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="tags-input filtered-tags-composer-chooser">
          {{#if @canEditTags}}
            <MiniTagChooser
              @value={{@value}}
              @onChange={{@onChange}}
              @options={{hash @options hiddenValues=this.hiddenValues}}
            />
            <PopupInputTip @validation={{@tagValidation}} />
          {{/if}}
        </div>
      
    */
    {
      "id": "iWgICJk2",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"tags-input filtered-tags-composer-chooser\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[32,0],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2],[30,3],[28,[32,1],[[30,4]],[[\"hiddenValues\"],[[30,0,[\"hiddenValues\"]]]]]]],null],[1,\"\\n        \"],[8,[32,2],null,[[\"@validation\"],[[30,5]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@canEditTags\",\"@value\",\"@onChange\",\"@options\",\"@tagValidation\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-filter/discourse/components/global-filter/filtered-tags-composer-chooser.js",
      "scope": () => [_miniTagChooser.default, _helper.hash, _popupInputTip.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FilteredTagsComposerChooser;
});